<template>
  <el-dialog
    v-model="centerDialogVisible"
    :title="dialogTitle"
    width="30%"
    :close-on-click-modal="false"
  >
    <el-form
      size="mini"
      label-width="auto"
      ref="refForm"
      :disabled="Formdisabled"
      :model="subForm"
      :rules="formRulesMixin"
    >
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="中队名称"
              prop="team_name"
              :rules="formRulesMixin.isNotNull"
            >
              <el-input
                v-model="subForm.team_name"
                placeholder="请输入中队名称"
              ></el-input>
            </el-form-item>
             <el-form-item label="队长名称" prop="manager" :rules="formRulesMixin.isNotNull">
                <el-input v-model="subForm.manager" placeholder="请输入队长名称"></el-input>
              </el-form-item>
            <el-form-item
              label="队长联系方式"
              prop="phone"
              :rules="formRulesMixin.mobile"
            >
              <el-input
                v-model="subForm.phone"
                placeholder="请输入队长手机号码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-divider></el-divider>
     <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="AddForm" size="mini">提交</el-button>
      </span>
     </template>
  </el-dialog>
</template>

<script setup>
import { defineExpose, getCurrentInstance, ref, defineEmits, reactive } from "vue";
import { ElNotification } from "element-plus";
import {StoreTeam,UpdateTeam} from "@/api/team.js"
let { proxy } = getCurrentInstance();
const emit = defineEmits(["fetchData"]);

//表单内容初始化
let subForm = reactive({
  id:'',
  team_name:'',
  phone:'',
  manager:''

  
})
//是否显示Dialog
const centerDialogVisible = ref(false);
//初始化Dialog标题
let dialogTitle = ref("");
const Formdisabled = ref(false)
//编辑框相关
 const showModal = (isEdit, insertdata) => {
  if (isEdit) {
    dialogTitle.value = `编辑${insertdata.team_name}`;
    centerDialogVisible.value = true;
    Formdisabled.value = false;
    reshowData(insertdata)

  } else {
    subForm.id = ''
    dialogTitle.value = "添加中队";
    centerDialogVisible.value = true;
    Formdisabled.value = false;
     proxy.$nextTick(() => {
      if (proxy.$refs["refForm"] != undefined) {
     proxy.$refs["refForm"].resetFields();
 }
    });
  }
};

//修改
 const reshowData = (row) => {
  proxy.$nextTick(() => {
    proxy.$refs["refForm"].resetFields();
    Object.keys(row).forEach((fItem) => {
      Object.keys(subForm).forEach((sItem) => {
        if (fItem === sItem) {
          subForm[sItem] = row[sItem];
        }else if(row[sItem] == undefined){
          subForm[sItem] = ' ';
        }
      });
    });
  });
};

const refForm = ref(null);
const eleMessage = (res) => {
  let type = "success";
  if (res.code != 200) {
    type = "error";
  } else if (res.code == 200) {
    type = "success";
    centerDialogVisible.value = false;
    emit("fetchData");
  }
  ElNotification({
    title: "提示",
    message: res.msg,
    type: type,
  });
};
//提交表单 校验
let AddForm = () => {
  refForm.value.validate((valid) => {
    if (valid) {
      //校验通过后的操作
      if (subForm.id) {
        //   updateReq()
        UpdateTeam(subForm).then(res=>{
          eleMessage(res)
        })
      } else {
        //  insertReq()
        StoreTeam(subForm).then(res=>{
          eleMessage(res)
        })
      }
    } else {
      return false;
    }
  });
};

defineExpose({
  showModal,
  eleMessage,
});
</script>




<style lang="scss" scoped>
.box-card {
  margin-bottom: 5px;
}

.dialog-footer {
  text-align: right;
}

.el-dialog__footer {
  padding: var(--el-dialog-padding-primary);
  padding-top: 10px;
  text-align: right;
  box-sizing: border-box;
}
</style>