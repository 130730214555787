import request from '@/utils/request'

//获取中队列表
export function getTeamList(pageid) {
    return request({
        url: '/admin/team/list/' + pageid,
        method: 'get'
    })
}
//获取中队详细信息
export function getTeam(id) {
    return request({
        url: '/admin/team/' + id,
        method: 'get'
    })
}
//新增中队
export function StoreTeam(data) {
    return request({
        url: '/admin/team/',
        method: 'post',
        data:data
    })
}
//修改中队信息
export function UpdateTeam(data) {
    return request({
        url: '/admin/team/'+ data.id,
        method: 'PUT',
        data: data
    })
}


export function DelTeam(id) {
    return request({
        url: '/admin/team/'+id,
        method: 'DELETE'
    })
}