<template>
  <div class="top">
    <el-button type="primary" size="mini" @click="OpenForm()">新增</el-button>
  </div>
  <AddBox ref="showForm" @fetchData="fetchData" />
  <el-table
    :data="tableData"
    border
    size="mini"
    :height="`calc(100vh - 210px)`"
    :highlight-current-row="true"
    style="width: 100%"
  >
    <el-table-column prop="id" label="id" width="60" />
    <el-table-column prop="team_name" align="center" label="中队名称" />
    <el-table-column prop="manager" align="center" label="中队长" />
    <el-table-column prop="phone" align="center" label="联系号码" />
    <el-table-column label="操作" width="270" align="center">
      <template #default="{ row }">
        <el-button-group>
          <el-button
            type="primary"
            :icon="Edit"
            size="mini"
            @click="tableEditClick(row)"
            >编辑</el-button
          >
          <el-popconfirm title="是否删除该中队?" @confirm="tableDelClick(row)">
            <template #reference>
              <el-button type="danger" :icon="Delete" size="mini"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
    background
    layout="total,prev, pager, next,jumper"
    :total="pageStruct.total"
    :page-size="pageStruct.pagesize"
    :current-page="pageStruct.currentpage"
    @current-change="handleCurrentChange"
  >
  </el-pagination>
</template>


<script setup>
import { toRefs, reactive, onBeforeMount, ref, getCurrentInstance } from "vue";
import { getTeamList,getTeam,DelTeam } from "@/api/team.js";
import { Edit, Delete } from "@element-plus/icons";
import AddBox from "../Team/components/AddTeam.vue";
let { proxy } = getCurrentInstance();
const showForm = ref(null);
//添加 查看 修改
let OpenForm = () => {
  proxy.$nextTick(() => {
    showForm.value.showModal();
  });
};
//分页结构体
let pageStruct = reactive({
  currentpage: 1,
  total: 0,
  pagesize: 20,
});

//修改
let tableEditClick = (row) => {
  getTeam(row.id).then((response) => {
    if (response.code != 200) {
      return false;
    }
    proxy.$nextTick(() => {
      showForm.value.showModal(true, response.data, false);
    });
  });
};
//删除
let tableDelClick = (row) => {
   DelTeam(row.id).then((response) => {
    showForm.value.eleMessage(response);
    fetchData();
  });
};

const state = reactive({
  tableData: null,
});
onBeforeMount(() => {
  fetchData();
});
const fetchData = () => {
  getTeamList(pageStruct.currentpage).then((response) => {
    state.tableData = response.data.list;
    pageStruct.total = response.data.count;
  });
};
//分页变化
const handleCurrentChange = (page) => {
  pageStruct.currentpage = page;
  fetchData();
};

//导出属性到页面中使用
let { tableData } = toRefs(state);
</script>

<style>
.top {
  margin-bottom: 20px;
}

.el-pagination {
  text-align: center;
}
.el-table__body tr.current-row > td {
  background-color: #66B1FF !important;
  color:#000;

  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */

}
 .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #e2e2e1;
  background-color:#ccc !important;

  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */

}

</style>